import React from "react";
import Slider from "react-rangeslider";

import { ReactComponent as ABSliderHandleBg } from "./ab-slider-handle-bg.svg";

export const ABSlider = ({ sliderVal, setSlider, newValue }) => {
  return (
    <div className="ab-slider">
      <div className="ab-slider-led-wrapper">
        <div className="ab-slider-led-off" />
        <div className="ab-slider-led-on" style={{ opacity: newValue }} />
      </div>

      <div className="ab-slider-innerplate">
        <Slider
          min={0}
          max={100}
          step={1}
          value={sliderVal}
          onChange={setSlider}
          orientation="vertical"
          tooltip={false}
          handleLabel={<ABSliderHandleBg className="inner-handle" />}
        />
      </div>
    </div>
  );
};
