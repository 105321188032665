import React, { useState } from 'react';
import ScrollLock from 'react-scrolllock';

import { ABSlider } from './ABSlider';
import { ReactComponent as ABImgLogo } from './ab-logo.svg';

import './style.css';

export const App = () => {
  const [sliderVal, setSlider] = useState(0);
  // const [lockScroll, setLockScroll] = useState(true);

  let oldValue = sliderVal;
  let oldMin = 0;
  let oldMax = 100;
  let newMin = 0;
  let newMax = 1;

  const newValue = ((oldValue - oldMin) / (oldMax - oldMin) ) * (newMax - newMin) + newMin;

//   useEffect(
//    () => {
//      // console.log('newValue ', newValue);
//      if (newValue < 0.8) {
//        // setResult(null);
//        // console.log(newValue);
//        setLockScroll(true);
//        return;
//      }
//
//      const timeout = setTimeout(() => {
//       setLockScroll(false)
//      }, 1000);
//
//      return () => clearTimeout(timeout);
//    },
//    [newValue]
 // );

  return (
    <ScrollLock>
      <div style={{ marginTop: 50, textAlign: 'center' }}>

        <ABImgLogo className="ab-logo" style={{ opacity: newValue, zoom: '110%' }} />

        <h2
          className="tagline"
          style={{
            fontSize: '2.2rem',
            margin: 'auto',
            marginBottom: 10,
            opacity: newValue
          }}
        >
          the human interface company<span style={{ fontSize: '1rem', position: 'relative', left: 3, top: -15, color: '#8c8c8c' }}>™</span>
        </h2>

        <h3
          className="tagtagline"
          style={{
            marginTop: 10,
            color: '#797979',
            fontWeight: 200,
            fontSize: '1.8rem',
            marginBottom: 50,
            opacity: newValue
          }}
        >
          we design and build custom hardware and software.
        </h3>

        <ABSlider sliderVal={sliderVal} setSlider={setSlider} newValue={newValue} />

        <p className="fade-in-6s" style={{ fontFamily: 'sans-serif', opacity: newValue, position: 'relative', top: 60 }}><a href="mailto:services@abstractly.io" style={{ textDecoration: 'none', color: '#30c3dd', textShadow: '0 0 10px #1b63a3' }}>services@abstractly.io</a></p>
        {/* <Container style={{ opacity: newValue }} className="main-content">
          <Row>
            <Col>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </Col>
          </Row>
        </Container> */}

      </div>
    </ScrollLock>
  );
}