import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { App } from "./App";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

ReactDOM.render(<AppWrapper />, document.getElementById("root"));
